export enum StorageKeys {
  GIFT_PERSONALIZE = "GIFT_PERSONALIZE",
  INITIAL_SETTINGS = "INITIAL_SETTINGS",
  CHECKOUT_RESULT = "CHECKOUT_RESULT",
}

export enum FeatureFlags {
  AIRTIME = "airtime-purchase",
  BILLS_PAYMENT = "bills-payment",
}
