import { createContext, useContext, useMemo } from "react";
import { useGetOrdersEnabledFeatures } from "../services/order";
import { useGetVouchersEnabledFeatures } from "../services/voucher";
import { useGetUserSecurityEnabledFeatures } from "../services/auth";
import { useGetContactsEnabledFeatures } from "../services/contact";
import { useGetMerchantsEnabledFeatures } from "../services/merchant";
import { useGetTransactionsEnabledFeatures } from "../services/transaction";
type FeaturesContextProps = {
  featureIsEnabled: (flag: string) => boolean | undefined;
};
const FeaturesContext = createContext<FeaturesContextProps>({} as FeaturesContextProps);

export const FeaturesContextProvider = ({ children }) => {
  const { data: orderFeatures } = useGetOrdersEnabledFeatures();
  const { data: vouchersFeatures } = useGetVouchersEnabledFeatures();
  const { data: contactsFeatures } = useGetContactsEnabledFeatures();
  const { data: merchantsFeatures } = useGetMerchantsEnabledFeatures();
  const { data: securityFeatures } = useGetUserSecurityEnabledFeatures();
  const { data: transactionsFeatures } = useGetTransactionsEnabledFeatures();
  const value: FeaturesContextProps = useMemo(() => {
    return {
      featureIsEnabled: (flag: string) => {
        return (
          orderFeatures?.includes(flag) ||
          vouchersFeatures?.includes(flag) ||
          contactsFeatures?.includes(flag) ||
          merchantsFeatures?.includes(flag) ||
          securityFeatures?.includes(flag) ||
          transactionsFeatures?.includes(flag)
        );
      },
    };
  }, [orderFeatures, vouchersFeatures, contactsFeatures, merchantsFeatures, securityFeatures, transactionsFeatures]);

  return <FeaturesContext.Provider value={value}>{children}</FeaturesContext.Provider>;
};

export const useFeaturesContext = () => {
  return useContext(FeaturesContext);
};
