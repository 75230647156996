import { useMutation, useQuery } from "react-query";
import { useAxios, ServerError, Count, PageFilter, NameAndId, GiftCardInfoData, GiftCardData } from "suregifts-component-lib";
import { AddItemModel } from "./cart";

export const useGetFeatureGiftCards = () => {
  const axios = useAxios();
  return useQuery<GiftCardData[], ServerError>({
    queryKey: ["feature-gifts"],
    keepPreviousData: true,
    initialData: [],
    refetchOnWindowFocus: false,
    retry: false,
    queryFn: () => axios.get("/orders/api/feature-gifts", {}),
  });
};
export const useGetGiftCards = (params: GiftCardFilter) => {
  const axios = useAxios();
  return useQuery<Count<GiftCardData>, ServerError>({
    queryKey: ["gift-cards", params],
    keepPreviousData: true,
    initialData: { items: [], total: 0 },
    refetchOnWindowFocus: true,
    retry: false,
    queryFn: () =>
      axios.get("/orders/api/gift-cards", {
        params,
      }),
  });
};
export const useGetGiftCardById = (giftId) => {
  const axios = useAxios();
  return useQuery<GiftCardInfoData, ServerError>({
    queryKey: ["gift-cardbyId", { giftId }],
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    retry: false,
    retryOnMount: false,
    enabled: !!giftId,
    refetchOnMount: false,
    queryFn: () => axios.get(`/orders/api/gift-cards/${giftId}`, {}),
  });
};
export const useGetCategories = () => {
  const axios = useAxios();
  return useQuery<NameAndId[], ServerError>({
    queryKey: ["giftcard-categories"],
    keepPreviousData: true,
    initialData: [],
    refetchOnWindowFocus: true,
    retry: false,
    queryFn: () => axios.get("/orders/api/categories", {}),
  });
};
export const useGetCountries = () => {
  const axios = useAxios();
  return useQuery<CountryDto[], ServerError>({
    queryKey: ["giftcard-countries"],
    keepPreviousData: true,
    initialData: [],
    refetchOnWindowFocus: true,
    retry: false,
    queryFn: () => axios.get("/orders/api/countries", {}),
  });
};
export const useGetGreetingCards = (onSuccess: (data: GreetingCardData[]) => void) => {
  const axios = useAxios();
  return useQuery<GreetingCardData[], ServerError>({
    queryKey: ["greeting-cards"],
    keepPreviousData: true,
    initialData: [],
    retry: false,
    refetchOnWindowFocus: false,
    queryFn: () => axios.get("/orders/api/greeting-cards", {}),
    onSuccess: onSuccess,
  });
};
export const useGetBgImages = () => {
  const axios = useAxios();
  return useQuery<string[], ServerError>({
    queryKey: ["bg-images"],
    keepPreviousData: true,
    initialData: [],
    queryFn: () => axios.get("/orders/api/bg-images", {}),
  });
};
export const useSaveBgImage = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, string>((path) => {
    return axios.post(`/orders/api/bg-images`, {
      path,
    });
  });
};
export const useDeleteBg = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, string>((path) => {
    return axios.delete(`/orders/api/bg-images`, {
      params: { path },
    });
  });
};

export interface GiftCardFilter extends PageFilter {
  orderBy: string;
  countryCode: string;
  categoryId: string;
}

export interface CountryDto {
  name: string;
  code: string;
}

export interface LocationDto {
  name: string;
  phone: string;
  email: string;
  address: string;
  state: string;
}
export interface GreetingCardData {
  name: string;
  greetingCards: GreetingCardItem[];
}

export interface GreetingCardItem {
  id: number;
  picture: string;
}
