import { Typography } from "@mui/material";
import { useCartDeliveryContext } from "../useCartDeliveryContext";

export const LeftBottom = () => {
  const { model } = useCartDeliveryContext();
  return (
    <div
      style={{
        background: "linear-gradient(180deg, #c8c8c9 -80%, #ffffff 52.8%)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingInline: "40px",
        textAlign: "center",
      }}
    >
      <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>Special Message</Typography>
      <Typography sx={{ fontSize: "14px", fontWeight: 300 }} component="div" dangerouslySetInnerHTML={{ __html: model.message }}></Typography>
    </div>
  );
};
