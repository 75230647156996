import { createContext, useContext, useEffect, useState } from "react";
import { initialData, useGetInitialData } from "../services/general";
import { CartData, useGetCart } from "../services/cart";
import usePaymentCallback from "../hooks/usePaymentCallback";
import { Box } from "@mui/material";
import { StorageKeys } from "../enums";
import { useSearchParams } from "react-router-dom";
import { useConfirmEmail } from "../services/auth";
import { useNotificationContext } from "suregifts-component-lib";
import { toast } from "react-toastify";
import { useGetOrdersEnabledFeatures } from "../services/order";

type GlobalContextValueProps = {
  cart?: CartData;
  refetchCart: () => void;
  initialSetting?: initialData;
};
const GlobalContext = createContext({} as GlobalContextValueProps);

export const useGlobalContext = () => {
  return useContext(GlobalContext);
};

type GlobalContextProviderProps = {
  children: React.ReactNode;
};
export const GlobalContextProvider = ({ children }: GlobalContextProviderProps) => {
  const [initialSetting, setInitialSetting] = useState(() => {
    const d = localStorage.getItem(StorageKeys.INITIAL_SETTINGS);
    if (!d) return undefined;
    return JSON.parse(d) as initialData;
  });
  useNotificationContext();
  const { mutate: getInitialState } = useGetInitialData();

  const { data: cart, refetch: refetchCart } = useGetCart();
  const [searchParams, setSearchParams] = useSearchParams();
  const { mutate: confirmEmail } = useConfirmEmail();

  useEffect(() => {
    getInitialState(
      {},
      {
        onSuccess: (data) => {
          localStorage.setItem(StorageKeys.INITIAL_SETTINGS, JSON.stringify(data));
          setInitialSetting(data);
        },
      }
    );
  }, []);
  useEffect(() => {
    const email = searchParams.get("email");
    const confirmToken = searchParams.get("confirm_token");
    if (!(email && confirmToken)) return;
    const confirmModel = { email, confirmToken };
    confirmEmail(confirmModel, {
      onSuccess: () => {
        toast.success("email confirmed");
      },
    });
    setSearchParams({});
  }, []);
  usePaymentCallback();

  return (
    <>
      <GlobalContext.Provider value={{ cart, refetchCart, initialSetting }}>
        <Box
          sx={{
            "& .MuiButton-containedPrimary.MuiButton-root": {
              backgroundColor: "primary.main",
            },
          }}
        >
          {children}
        </Box>
      </GlobalContext.Provider>
    </>
  );
};
