import { useMemo, useState } from "react";
import { Box } from "@mui/system";
import { Container, Typography, useTheme } from "@mui/material";

import { BillCard, PageHeaderTitle, Pagination, useDocumentTitle, CustomSearchInput, useFilter, LoadingBox, AirtimeFilter, useGetAirtimeCountries, useGetAirtimes } from "suregifts-component-lib";

import { useGlobalContext } from "../../../../contexts/GlobalContext";
import { NavItem } from "../../bills/list/NavItem";

function AirtimeListScreen() {
  const theme = useTheme();
  const { initialSetting } = useGlobalContext();
  const params = useMemo(() => {
    let countryCode: any = undefined;
    if (initialSetting?.countryCode === "NG" || initialSetting?.countryCode === "KE") {
      countryCode = initialSetting.countryCode;
    }
    return { countryCode: countryCode, page: 1, size: 12 } as AirtimeFilter;
  }, []);
  const [filter, setFilter] = useFilter<AirtimeFilter>(params);
  const { data, isFetching } = useGetAirtimes(filter);
  const { data: countries } = useGetAirtimeCountries();
  const handleFilter = (params) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  useDocumentTitle("Airtime Purchase");
  return (
    <Container sx={{ marginTop: "40px" }}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: " 1fr",
          "& nav": {
            display: "none",
          },
          gap: "60px",
          [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "250px 1fr",
            "& nav": {
              display: "block !important",
            },
          },
        }}
      >
        <Box component={"nav"}>
          <PageHeaderTitle title="Airtime" sx={{ marginBottom: "50px" }} />
          <div>
            <NavTitle title={"Search"} />
            <CustomSearchInput
              onChange={(e) => handleFilter({ search: e })}
              sx={{
                marginTop: "20px",
                backgroundColor: "primary.main",
                "& input::placeholder": {
                  color: "text.primary",
                  opacity: 1,
                },
              }}
              placeholder="Search here... e.g mtn"
            />
          </div>
          <div style={{ marginTop: "50px" }}>
            <NavTitle title={"Sort By"} />
            <div style={{ display: "grid", gap: "20px", marginTop: "35px" }}>
              {countries?.map((item) => (
                <NavItem key={item.id} name={item.name} onClick={() => handleFilter({ countryCode: item.id })} selected={filter.countryCode === item.id} />
              ))}
            </div>
          </div>
        </Box>
        <div>
          <LoadingBox
            loading={isFetching}
            sx={{
              display: "grid",
              gap: "1.25rem",
              [theme.breakpoints.up("sm")]: {
                gridTemplateColumns: "repeat(2,1fr)",
              },
              [theme.breakpoints.up("md")]: {
                gridTemplateColumns: "repeat(3,1fr)",
              },
            }}
          >
            {data?.items.map((item) => (
              <BillCard categoryName={`Airtime(${item.countries})`} key={item.airtimeId} url={`${item.airtimeId}`} name={item.name} logoUrl={item.logoUrl} />
            ))}
          </LoadingBox>
          <div style={{ marginTop: "1.25rem" }}>
            <Pagination
              align="center"
              size={filter.size}
              page={filter.page}
              total={data?.total}
              onChange={(page) => {
                handleFilter({ page });
              }}
            />
          </div>
        </div>
      </Box>
    </Container>
  );
}
export const NavTitle = ({ title }) => {
  return <Typography sx={{ marginBottom: "20px", fontSize: "12px", textTransform: "uppercase", fontWeight: 500, color: "text.secondary", lineHeight: "15px" }}>{title}</Typography>;
};

export default AirtimeListScreen;
