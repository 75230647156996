import { Box, Stack, SvgIconProps, Typography, useTheme } from "@mui/material";
import React, { ComponentType, useMemo } from "react";
import { DeliveryTruckIcon } from "suregifts-component-lib";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { PencilIcon } from "../../../icons/Icons";
const icons: React.ComponentType<SvgIconProps>[] = [PencilIcon, DeliveryTruckIcon, CreditCardIcon];
type CartStepViewProps = {
  title: string;
  title2: string;
  index: number;
  step: number;
  icon: ComponentType<SvgIconProps>;
};

export const CartStepView = ({ step, index, title, title2, icon: Icon }: CartStepViewProps) => {
  const theme = useTheme();

  const bgcolor = useMemo(() => {
    if (index < step) return "secondary.main";
    if (index === step) return "transparent";

    return "primary.main";
  }, []);

  const iconColor = useMemo(() => {
    if (index < step) return "secondary.contrastText";
    if (index === step) return "primary.contrastText";
    return "#5d5d60";
  }, []);
  const text2Color = useMemo(() => {
    if (index < step) return "rgba(255, 255, 255, 0.3)";
    if (index === step) return "primary.contrastText";
    return "rgba(255, 255, 255, 0.3)";
  }, []);
  return (
    <Stack direction={"row"} spacing={3}>
      <Box sx={{ display: "flex", gap: "20px", alignItems: "center" }}>
        <Box
          sx={{
            borderRadius: "50vh",
            backgroundColor: bgcolor,
            height: "50px",
            width: "50px",
            padding: "1rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: index <= step ? 1 : 0,
          }}
        >
          <Icon sx={{ color: iconColor, fontSize: "24px" }} />
        </Box>
        <div style={{ display: "flex", gap: "5px", flexDirection: "column" }}>
          <Typography sx={{ color: "text.secondary", fontSize: "14px", lineHeight: "17px", fontWeight: 500 }}>{title}</Typography>
          <Typography sx={{ color: text2Color, fontSize: "18px", lineHeight: "20px", fontWeight: 500 }}>{title2}</Typography>
        </div>
      </Box>
    </Stack>
  );
};
