import { useMutation, useQuery } from "react-query";
import { Count, NameAndId, PageFilter, ServerError, useAxios } from "suregifts-component-lib";

export const useGetContacts = (params: PageFilter) => {
  const axios = useAxios();
  return useQuery<Count<ContactData>, ServerError>({
    queryKey: ["contacts", params],
    keepPreviousData: true,
    initialData: { total: 0, items: [] },
    queryFn: () =>
      axios.get("/contacts/api", {
        params,
      }),
  });
};
export const useGetContactById = (contactId, onSuccess: (data: ContactData) => void) => {
  const axios = useAxios();
  return useQuery<ContactData, ServerError>({
    queryKey: ["contactById", { contactId }],
    keepPreviousData: true,
    queryFn: () => axios.get(`/contacts/api/${contactId}`, {}),
    onSuccess: onSuccess,
    enabled: !!contactId,
  });
};
export const useGetRepeatTypes = () => {
  const axios = useAxios();
  return useQuery<NameAndId[], ServerError>({
    queryKey: ["repeat-types"],
    keepPreviousData: true,
    initialData: [],
    queryFn: () => axios.get("/contacts/api/event-repeat-types", {}),
  });
};
export const useGetContactsEnabledFeatures = () => {
  const axios = useAxios();
  return useQuery<string[], ServerError>({
    queryKey: ["contacts-enabled-features"],
    keepPreviousData: true,

    queryFn: () =>
      axios.get("/contacts/api/enabled-features", {

      }),
    initialData: [],
  });
};
export const useGetReminderType = () => {
  const axios = useAxios();
  return useQuery<NameAndId[], ServerError>({
    queryKey: ["reminder-type"],
    keepPreviousData: true,
    initialData: [],
    queryFn: () => axios.get("/contacts/api/event-reminder-type", {}),
  });
};
export const useDeleteContact = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, any>((contactId) => {
    return axios.delete(`/contacts/api/${contactId}`);
  });
};
export const useAddContact = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, ContactModel>((model) => {
    return axios.post(`/contacts/api`, model);
  });
};
export const useEditContact = (contactId) => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, ContactModel>((model) => {
    return axios.post(`/contacts/api/${contactId}`, model);
  });
};
export const useAddEvent = (contactId) => {
  var axios = useAxios();
  return useMutation<ContactData, ServerError, EventModel>((model) => {
    return axios.post(`/contacts/api/${contactId}/events`, model);
  });
};
export const useEditEvent = (contactId) => {
  var axios = useAxios();
  return useMutation<ContactData, ServerError, EventModel>((model) => {
    return axios.post(`/contacts/api/${contactId}/events/${model.id}`, model);
  });
};
export const useDeleteEvent = (contactId) => {
  var axios = useAxios();
  return useMutation<ContactData, ServerError, any>((eventId) => {
    return axios.delete(`/contacts/api/${contactId}/events/${eventId}`);
  });
};
export interface ContactModel {
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  phone: string;
  events: EventModel[];
}

export interface EventModel {
  id: any;
  title?: string;
  startDate?: string;
  reminderType?: string;
  repeatType?: string;
  description?: string;
}

export interface ContactData {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  events: EventData[];
}

export interface EventData {
  id: number;
  title: string;
  startDate: string;
  repeatTypeId: string;
  RepeatType: string;
  reminderTypeId: string;
  ReminderType: string;
  description: string;
}
