import { Stack, IconButton, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuthContext, FormButton, SelectPopover, CountryDropdown, SiteSwitchButton } from "suregifts-component-lib";
import { useGlobalContext } from "../../contexts/GlobalContext";

import MenuIcon from "@mui/icons-material/Menu";
import { LoggedInUserView } from "./LoggedInUserView";
import { CartIconView } from "./CartIconView";
import { useFeaturesContext } from "../../contexts/FeaturesContext";
import { useMemo } from "react";
import { FeatureFlags } from "../../enums";

export const UserStatusSection = () => {
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuthContext();
  const { initialSetting } = useGlobalContext();
  const { featureIsEnabled } = useFeaturesContext();

  const links = useMemo(() => {
    var l = [{ text: "Home", to: "/" }];
    if (featureIsEnabled(FeatureFlags.AIRTIME)) {
      l.push({ text: "Airtime", to: "/airtime" });
    }
    if (featureIsEnabled(FeatureFlags.BILLS_PAYMENT)) {
      l.push({ text: "Pay bills", to: "/bills" });
    }
    l.push({ text: "Merchants", to: "/merchant" });

    return l;
  }, [featureIsEnabled]);
  return (
    <Stack direction={"row"} spacing={"20px"} sx={{ alignItems: "center" }}>
      {initialSetting?.businessUrl && <SiteSwitchButton label="Suregifts Business" url={initialSetting?.businessUrl} />}
      {isAuthenticated ? (
        <>
          <div style={{ display: "flex", gap: "20px", alignItems: "center", justifyItems: "center" }}>
            <CountryDropdown
              value={initialSetting?.countryCode}
              options={initialSetting?.countries}
              onChange={(index, value) => {
                window.location.href = initialSetting!.countries[index]!.url;
              }}
              hideTitle
            />
            <CartIconView />
            <LoggedInUserView />
          </div>
        </>
      ) : (
        <>
          <FormButton label="Sign up" onClick={() => navigate("/register")} />
          <FormButton label="Sign in" variant="outlined" onClick={() => navigate("/login")} />
          <CountryDropdown
            value={initialSetting?.countryCode}
            options={initialSetting?.countries}
            onChange={(index, value) => {
              window.location.href = initialSetting!.countries[index]!.url;
            }}
            hideTitle
          />
        </>
      )}

      <SelectPopover
        options={links}
        onChange={(index) => {
          const to = links[index].to;
          if (to) {
            navigate(to);
          }
        }}
        render={(handleClick) => (
          <IconButton onClick={handleClick} className="menu-button">
            <MenuIcon />
          </IconButton>
        )}
      />
    </Stack>
  );
};
